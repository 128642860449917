<template>
  <Loading v-if="isLoading" />
  <div v-else class="show-message">
    <b-card>
      <h2 class="m-0 page-title">{{ $t("messages.contact_us") }}</h2>
      <hr />
      <div class="message-info">
        <h3 class="sub-title mb-3">{{ $t("messages.message_information") }}</h3>
        <ul>
          <li>
            <strong>{{ $t("messages.sender_name") }}</strong
            >{{ message_info.name }}
          </li>
          <li>
            <strong>{{ $t("messages.email") }}</strong
            >{{ message_info.email }}
          </li>
          <li>
            <strong>{{ $t("messages.phone") }}</strong
            >{{ message_info.phone }}
          </li>
          <li>
            <strong>{{ $t("messages.subject") }}</strong
            >{{ message_info.subject }}
          </li>
          <li class="list-item">
            <strong>{{ $t("messages.message") }}</strong>
            <div class="message-box">
              <div class="content-message">
                {{ message_info.message }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="reply mt-3">
        <h3 class="sub-title">{{ $t("messages.reply") }}</h3>
        <hr />
        <validation-observer ref="replyValidation">
          <b-form @submit.prevent>
            <b-form-group :label="$t('messages.reply')">
              <validation-provider
                #default="{ errors }"
                :name="$t('messages.reply')"
                rules="required"
              >
                <b-form-textarea
                  v-model="form_data.message"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                  :disabled="message_info.is_closed"
                />
                <small class="text-danger">{{
                  errors[0]
                    ? $helpers.translateValidations(
                        errors[0],
                        $t("messages.reply")
                      )
                    : errors[0]
                }}</small></validation-provider
              >
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              @click="handleReply"
              class="primary-btn mt-3"
              :disabled="isPending || message_info.is_closed"
            >
              <span>{{ $t("messages.send_reply") }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormTextarea,
  BForm,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "ShowMessage",
  components: {
    BCard,
    BForm,
    BFormGroup,
    BButton,
    Loading,
    OverlayComponent,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isPending: null,
      isLoading: true,
      overlayLoading: false,
      current_id: null,
      message_info: null,

      form_data: {
        id: null,
        message: null,
      },
      reply_type: [
        {
          value: "email",
          text: "Email",
        },
        {
          value: "sms",
          text: "SMS",
        },
      ],
    };
  },
  created() {
    this.current_id = this.form_data.id = this.$route.params.id;
    this.getMessage();
  },
  methods: {
    async getMessage() {
      try {
        const response = (
          await this.$http.get(`admin/contacts/${this.current_id}`)
        ).data.data;
        this.message_info = response;
        if (response.reply) {
          this.form_data.message = response.reply;
        }
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    handleReply() {
      this.$refs.replyValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            formData.append(key, this.form_data[key]);
          }

          this.$http
            .post("admin/reply", formData)
            .then((res) => {
              if (res.status === 200) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "messages" });
                }, 500);
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
